import React, { useEffect } from "react"
import LayoutProvider from "../../../components/Layout"
import { navigate } from 'gatsby';
import TablaSeguimiento from "../../../components/ReclamosComponents/Tabla/TablaSeguimiento";

const AntecedentesPage = ({ location }) => {
  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }

    
  }, [])

  

  return (
    <LayoutProvider title="Reclamos " location={location}>
        <div className="contenedor">
          <TablaSeguimiento location={location}/>
        </div>
    </LayoutProvider>
  )
}

export default AntecedentesPage
